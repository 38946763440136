<script setup lang='ts'>
import { imageTransform, swiperPath } from '@qcwp/common'
import type { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'
const props = withDefaults(defineProps<{
  adKey?: AD_KEYS
  position?: AD_POSITION
  imgProps?: Record<string, any>
}>(), {})
const { adData } = getAdComponentData(props.adKey, props.position)
</script>

<template>
  <template v-if="adKey && position && adData">
    <NuxtLink
      v-for="ad in adData" :key="ad.adLink"
      class="block w-75.8 flex-shrink-0 h-48 mt-6.5 self-start rounded-none overflow-hidden relative"
      :to="swiperPath(ad.adDataType, { articleType: ad.dataArticleVO?.articleType, id: ad.adDataId, url: ad.adLink })" target="_blank"
    >
      <BaseImg class="w-full h-full object-cover" v-bind="imgProps" :src="imageTransform(getPlatform(ad), { format: 'webp' })" :alt="ad.adTitle" />
      <h3 v-if="ad?.adTitle" class="truncate bg-black bg-opacity-50 absolute bottom-0 left-0 right-0 px-2 py-1 text-white">
        {{ ad.adTitle }}
      </h3>
    </NuxtLink>
  </template>
</template>

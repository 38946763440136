<script lang="ts" setup>
import { isClient, min } from '@qcwp/utils'
import { useElementSize } from '@vueuse/core'

const props = withDefaults(defineProps<{
  isShowError?: boolean
  bgClass?: string
  previewDisabled?: boolean
}>(), {
  previewDisabled: true,
  isShowError: true,
  bgClass: 'bg-gray-100 text-white',
})
const placeholderRef = ref<HTMLElement | null>(null)
const { width, height } = useElementSize(placeholderRef as any)
const fontSize = computed(() => {
  return Math.floor(min([width.value, height.value, 100]) / 2)
})
function transform(data: string | number): string {
  if (isNaN(Number(data)))
    return String(data) || ''
  else
    return `${data}px`
}
const loadError = ref(false)
const imageRef = ref<HTMLElement | null>(null)
const isMounted = ref(false)

onMounted(() => {
  isMounted.value = true
})
</script>

<template>
  <n-image
    v-if="isMounted"
    ref="imageRef"
    class="text-3"
    :class="[!loadError || isShowError ? 'w-full h-full' : 'w-0 h-0']"
    :preview-disabled="previewDisabled"
    v-bind="$attrs"
    :lazy="isClient ? true : false"
    :style="!loadError || isShowError ? `width: ${transform($attrs.width as string)}; height: ${transform($attrs.height as string)};` : 'width:0;height:0;'"
    @error="loadError = true"
  >
    <template #placeholder>
      <div
        ref="placeholderRef"
        class="w-full h-full grid place-items-center"
        :class="[bgClass]"
      >
        <i :class="[loadError || !$attrs?.src ? 'i-ic:round-hide-image' : 'i-clarity:image-solid']" :style="`font-size:${fontSize}px`" />
      </div>
    </template>
  </n-image>
</template>

<style lang="scss" scoped>
  .n-image {
    display: flex;
    justify-content: center;
  }

  :deep(img) {
    width: 100%;
  }
</style>

<script setup lang='ts'>
import { imageTransform, swiperPath } from '@qcwp/common'
import { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'

const { isMobileOrTablet } = useDevice()
const { adData } = getAdComponentData(AD_KEYS.HOME, AD_POSITION.A8)
</script>

<template>
  <template v-if="adData">
    <div v-for="ad in adData" :key="ad.adLink" class="w-40 h-45  bg-white rounded-md px-2 py-5 mr-4 relative shadow-md flex-shrink-0 group">
      <NuxtLink
        flex="~ col" items-center gap-1 target="_blank"
        :to="swiperPath(ad.adDataType, { articleType: ad.dataArticleVO?.articleType, id: ad.adDataId, url: ad.adLink })"
      >
        <div class="w-14 h-14">
          <base-image preview-disabled lazy :src="imageTransform(getPlatform(ad), { format: 'webp' })" :alt="ad.adTitle" />
        </div>
        <n-ellipsis text-4 font-black :tooltip="!isMobileOrTablet">
          {{ ad.adTitle }}
        </n-ellipsis>
        <!-- <div v-if="ad.remark">
          主营:{{ ad.remark }}
        </div> -->
        <div v-if="ad.adTitle" text-error text-3>
          电话：{{ ad.remark }}
        </div>
      </NuxtLink>
    </div>
  </template>
</template>

<style lang='scss' scoped>

</style>

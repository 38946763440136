import type { AdResponse } from '~~/src/server/modules/ad/types'
import { AD_KEYS } from '~~/src/server/modules/ad/types'

export function getPlatform(item: AdResponse, key: 'img' | 'size' = 'img') {
  const { isMobileOrTablet } = useDevice()
  if (key === 'size')
    return isMobileOrTablet ? item.appImgSize : item.pcImgSize

  const img = (isMobileOrTablet ? item.appImgUrl : item.pcImgUrl) ?? item.dataArticleVO?.imgs?.[0] ?? ''
  return img
}

export function getAdKey() {
  const route = useRoute()
  // index | media | news | car | dealer
  const key = computed(() => {
    const { path } = route
    if (path.match(/\/(dealer)/))
      return AD_KEYS.DEALER
    else if (path.match(/\/(article)/) && path.match(/\/article\/articleType/))
      return AD_KEYS.MEDIA_HOME
    else if (path.match(/\/(news|video|live)\/\d+/))
      return AD_KEYS.NEWS_DETAIL
    else if (path.match(/\/(news|video|live)/))
      return AD_KEYS.NEWS_HOME
    else if (path.match(/\/(series|car|koubei)/))
      return AD_KEYS.SERIES_DETAIL
    else
      return AD_KEYS.HOME
  })
  return key
}

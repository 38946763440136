<script lang="ts" setup>
import { isNumber } from '@qcwp/utils'
const props = withDefaults(defineProps<{
  component?: string | Component
  proportion?: string | number
  src?: string
  isShowError?: boolean
  imgProps?: Record<string, any>
}>(), {
  component: 'div',
  proportion: 100,
  isShowError: true,
})

const height = computed(() => {
  if (isNumber(props.proportion))
    return `${props.proportion}%`
  else
    return props.proportion
})
</script>

<template>
  <component :is="component" class="relative pl-100% pt-[var(--height)]" :style="{ '--height': height }">
    <div class="absolute top-0 left-0 right-0 bottom-0">
      <img w-full h-full :src="props.src" v-bind="props.imgProps">
    </div>
    <slot />
  </component>
</template>

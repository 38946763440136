<script setup lang='ts'>
import { imageTransform, swiperPath } from '@qcwp/common'
import { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'

const { adData } = getAdComponentData(AD_KEYS.HOME, AD_POSITION.A2)
</script>

<template>
  <template v-if="adData">
    <NuxtLink
      v-for="ad in adData" :key="ad.adLink" :title="ad.adTitle"
      :to="swiperPath(ad.adDataType, { articleType: ad.dataArticleVO?.articleType, id: ad.adDataId, url: ad.adLink })"
      flex-grow flex flex-col items-center target="_blank"
    >
      <base-image
        width="78" preview-disabled height="56" object-fit="cover" lazy
        :alt="ad.adTitle"
        :src="imageTransform(getPlatform(ad), { format: 'webp' })"
      />
      <div text-center truncate>
        {{ ad.adTitle }}
      </div>
    </NuxtLink>
  </template>
</template>

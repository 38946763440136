<script setup lang='ts'>
import { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'

const { adData } = getAdComponentData(AD_KEYS.HOME, AD_POSITION.A5)
</script>

<template>
  <template v-if="adData && adData.length">
    <div v-for="ad, i in adData" v-bind="$attrs" :key="i" class="flex md:flex-col h-full w-full" py-3 border="b-1 gray-100" md="py-0 border-b-0">
      <layout-article-item :article="ad.dataArticleVO" />
    </div>
  </template>
</template>

<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-22 09:11:33
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-06-27 11:07:36
 * @FilePath: \qcwp-vue\packages\web\src\components\ad\common\A1.client.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script setup lang='ts'>
import { imageTransform, swiperPath } from '@qcwp/common'
import type { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'
const props = withDefaults(defineProps<{
  adKey?: AD_KEYS
  position?: AD_POSITION
  imgProps?: Record<string, any>
}>(), {})
const { isDesktop } = useDevice()
const { adData } = getAdComponentData(props.adKey, props.position)
</script>

<template>
  <div v-if="adKey && position && adData">
    <n-carousel :autoplay="true" :show-dots="false">
      <NuxtLink
        v-for="ad in adData" :key="ad.adLink" class="block h-auto mb-3 overflow-hidden relative"
        :to="swiperPath(ad.adDataType, { articleType: ad.dataArticleVO?.articleType, id: ad.adDataId, url: ad.adLink })" target="_blank"
      >
        <BaseImg
          class="w-full" v-bind="imgProps"
          :src="imageTransform(getPlatform(ad), { format: 'webp' })"
          :alt="ad?.adTitle"
        />
        <h3 v-if="ad?.adTitle && isDesktop" class="truncate bg-black bg-opacity-50 absolute bottom-0 left-0 right-0 px-2 py-1 text-white">
          {{ ad.adTitle }}
        </h3>
      </NuxtLink>
    </n-carousel>
  </div>
</template>

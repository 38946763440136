<script setup lang='ts'>
import { useElementSize } from '@vueuse/core'
import { isClient, min } from '@qcwp/utils'
const props = withDefaults(defineProps<{
  src?: string
  isShowError?: boolean
  imgProps?: Record<string, any>
  lazy?: boolean
}>(), {
  src: '',
  isShowError: true,
})

const imageRef = ref<HTMLElement | null>(null)
const { width, height } = useElementSize(imageRef as any)
const fontSize = computed(() => {
  return Math.floor(min([width.value, height.value, 100]) / 2)
})
const status = ref<'loading' | 'error' | 'loaded'>('loading')
const isMounted = ref(false)
function loadHandle() {
  status.value = 'loaded'
}
function errorHandle() {
  status.value = 'error'
}

const stop = watch(imageRef, () => {
  if (imageRef)
    isMounted.value = true
})
onBeforeUnmount(stop)
</script>

<template>
  <div v-if="!(status === 'error' && !isShowError)" ref="imageRef" class="relative">
    <img
      v-if="props.src && isMounted && status !== 'error'"
      class="w-full"
      :loading="props.lazy && isClient ? 'lazy' : 'auto'"
      :src="props.src"
      v-bind="$attrs"
      @load="loadHandle"
      @error="errorHandle"
    >
    <div v-if="props.src && status === 'loading'" class="w-full h-full absolute absolute-0">
      <slot name="loading">
        <div class="w-full h-full bg-gray-200 text-white grid place-items-center">
          <i class="i-clarity:image-solid" :style="`font-size:${fontSize}px`" />
        </div>
      </slot>
    </div>
    <div v-if="(status === 'error' || !props.src) && isShowError" class="w-full h-full absolute absolute-0" :data-src="props.src">
      <slot name="loading">
        <div class="w-full h-full bg-gray-200 text-white grid place-items-center">
          <i class="i-ic:round-hide-image" :style="`font-size:${fontSize}px`" />
        </div>
      </slot>
    </div>
  </div>
</template>
